import { InstanceError } from '@apptypes/instance';
import { IPagination } from '@apptypes/pagination';
import { generateToQueryString } from '@helpers/queryString';
import { ERROR_STATUS_MESSAGE } from '@helpers/request/constants';
import instance from '@helpers/request/instance';
import { GetAllStatements, IContractCreate, IExtract } from '@modules/Contract/types';
import { ColumnFiltersState } from '@tanstack/react-table';
import { getCookie } from '@helpers/cookies';
import { AUTH_COOKIE_NAME } from '@helpers/constants';

export const getAll = async (
  page = 1,
  size = 10,
  clientID?: string,
  filterItems?: ColumnFiltersState,
): Promise<IPagination<IContractCreate>> => {
  try {
    const queryString = generateToQueryString({
      page,
      size,
      filterItems,
      otherParams: clientID ? `&client_id=${clientID}` : '',
    });
    const { data } = await instance.get(`/contracts${queryString}`);
    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'contrato não encontrado',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const getByID = async (contractID: string): Promise<IContractCreate> => {
  try {
    const { data } = await instance.get(`/contracts/${contractID}`);
    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'contrato não encontrado',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const create = async (payload: IContractCreate): Promise<IContractCreate> => {
  try {
    const { data } = await instance.post('/contracts', {
      ...payload,
      active: true,
    });
    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível criar o contrato',
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'contrato não encontrado',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const update = async (
  contractID: string,
  payload: IContractCreate,
): Promise<IContractCreate> => {
  try {
    const { data } = await instance.put(`/contracts/${contractID}`, payload);
    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível atualizar o contrato',
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'contrato não encontrado',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const deleteByID = async (contractID: string): Promise<void> => {
  try {
    await instance.delete(`/contracts/${contractID}`);
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível deletar o contrato',
      [ERROR_STATUS_MESSAGE.UNAUTHORIZED]: 'Você não tem permissão para deletar o contrato',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const getAllStatements = async ({
  contractID,
  initialDate,
  finalDate,
}: GetAllStatements): Promise<IExtract> => {
  try {
    const { data } = await instance.get(
      `/reports/statements/${contractID}?initial_date=${initialDate}&final_date=${finalDate}`,
    );

    return data;
  } catch (error) {
    const errorMessage: Record<string, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Extratos não encontradas',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const getTotalValue = async (
  contractID: string,
): Promise<{ contract_id: string; total_value: number }> => {
  try {
    const { data } = await instance.get(`/contracts/${contractID}/total-value`);

    return data;
  } catch (error) {
    const errorMessage: Record<string, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Extratos não encontradas',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const exportData = async (page = 1, size = 1000, filterItems?: ColumnFiltersState) => {
  try {
    const queryString = generateToQueryString({ page, size, filterItems });
    const file = await fetch(`${process.env.BASE_URL}/contracts/report/export${queryString}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: getCookie(AUTH_COOKIE_NAME),
      } as any,
    });

    const response = await file.arrayBuffer();
    const fileBlog = new Blob([response], { type: 'text/csv' });

    return URL.createObjectURL(fileBlog);
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível buscar o arquivo',
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'arquivo não encontrado',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const generateContract = async (contractId: string) => {
  try {
    const { data } = await instance.get(`/contracts-files/${contractId}/generate-file`);
    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Não foi possível gerar o contrato.',
    };

    let message = errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER];

    if (
      (error as InstanceError).message ===
      'No contract transactions not found with this contract_id'
    ) {
      message = 'Contrato sem transação.';
    }

    throw new Error(message);
  }
};
