import { InstanceError } from '@apptypes/instance';
import { IPagination } from '@apptypes/pagination';
import { generateToQueryString } from '@helpers/queryString';
import { ERROR_STATUS_MESSAGE } from '@helpers/request/constants';
import instance from '@helpers/request/instance';
import { IProfitabilityGroupCreate } from '@modules/ProfitabilityGroup/types';
import { ColumnFiltersState } from '@tanstack/react-table';
import { getCookie } from '@helpers/cookies';
import { AUTH_COOKIE_NAME } from '@helpers/constants';

export const getAll = async (
  page = 1,
  size = 10,
  filterItems?: ColumnFiltersState,
): Promise<IPagination<IProfitabilityGroupCreate>> => {
  try {
    const queryString = generateToQueryString({ page, size, filterItems });
    const { data } = await instance.get(`/profitability-group${queryString}`);
    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Grupo de rentabilidade não encontrado',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const getByID = async (profitabilityGroupID: string): Promise<IProfitabilityGroupCreate> => {
  try {
    const { data } = await instance.get(`/profitability-group/${profitabilityGroupID}`);
    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Grupo de rentabilidade não encontrado',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const create = async (
  payload: IProfitabilityGroupCreate,
): Promise<IProfitabilityGroupCreate> => {
  try {
    const { data } = await instance.post('/profitability-group', {
      ...payload,
      percentage: Number(payload.percentage),
    });
    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível criar o grupo de rentabilidade',
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Grupo de rentabilidade não encontrado',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const update = async (
  profitabilityGroupID: string,
  payload: IProfitabilityGroupCreate,
): Promise<IProfitabilityGroupCreate> => {
  try {
    const { data } = await instance.put(`/profitability-group/${profitabilityGroupID}`, {
      ...payload,
      percentage: Number(payload.percentage),
    });
    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível atualizar o grupo de rentabilidade',
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Grupo de rentabilidade não encontrado',
      [ERROR_STATUS_MESSAGE.UNAUTHORIZED]:
        'Você não tem permissão para editar o grupo de rentabilidade',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const deleteByID = async (profitabilityGroupID: string) => {
  try {
    const response = await instance.delete(`/profitability-group/${profitabilityGroupID}`);
    return response;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível deletar o grupo de rentabilidade',
      [ERROR_STATUS_MESSAGE.UNAUTHORIZED]:
        'Você não tem permissão para deletar o grupo de rentabilidade',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const exportData = async (page = 1, size = 1000, filterItems?: ColumnFiltersState) => {
  try {
    const queryString = generateToQueryString({ page, size, filterItems });
    const file = await fetch(
      `${process.env.BASE_URL}/profitability-group/report/export${queryString}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          authorization: getCookie(AUTH_COOKIE_NAME),
        } as any,
      },
    );

    const response = await file.arrayBuffer();
    const fileBlog = new Blob([response], { type: 'text/csv' });

    return URL.createObjectURL(fileBlog);
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível buscar o arquivo',
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'arquivo não encontrado',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};
