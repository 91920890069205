import { IClientDocumentsCreate } from '@modules/Client/types';
import React from 'react';
import { Link } from 'react-router-dom';
import { Badge, Button, Card, CardBody, Col, Row } from 'reactstrap';

interface IDetail {
  identificationNumber: string;
  clientName: string;
  clientDocument: IClientDocumentsCreate[];
  profitabilityGroupDescription: string;
  profitabilityGroupPercentage: string;
  partnerName: string;
  partnerID?: string;
  contractID?: string;
  recurringWithdrawal?: boolean;
  handleGenerateContract: () => void;
  generateContractPending: boolean;
}

export const Details = ({
  clientName,
  clientDocument,
  contractID,
  identificationNumber,
  partnerName,
  partnerID,
  profitabilityGroupDescription,
  profitabilityGroupPercentage,
  recurringWithdrawal,
  handleGenerateContract,
  generateContractPending,
}: IDetail) => (
  <Card className="hidden-print">
    <CardBody>
      <Row className="align-items-center">
        <Col lg={6}>
          <Badge pill color="primary" className="font-size-14">
            #{identificationNumber}
          </Badge>
          <span className="font-size-14 ms-2">{clientName} - </span>
          {clientDocument.length > 0 && (
            <>
              <span className="font-size-14 ms-1">{clientDocument[0].type}</span>
              <span className="font-size-14 ms-1">{clientDocument[0].value}</span>
            </>
          )}
          <span className="font-size-14 ms-1">- {profitabilityGroupDescription}</span>
          <span className="font-size-14 ms-1">{profitabilityGroupPercentage}%</span>

          {recurringWithdrawal && (
            <span className="recurring-withdrawal">
              <i className="mdi mdi-reload ms-2 icon-recurring-withdrawal"></i> Retirada Recorrente
            </span>
          )}
        </Col>

        <Col lg={6} className="align-items-center">
          <div className="d-flex justify-content-end align-items-center">
            {partnerName ? (
              <Link
                to={`/contracts/${contractID}/link-partner/${partnerID}`}
                className="font-size-14 text-primary"
              >
                <strong>Acessor vinculado</strong>: {partnerName}
              </Link>
            ) : (
              <Link
                to={`/contracts/${contractID}/link-partner`}
                className="font-size-14 text-primary"
              >
                <i className="mdi mdi-plus-circle-outline me-1"></i> Vincular acessor
              </Link>
            )}

            <Button
              type="button"
              size="sm"
              color="primary"
              className="ms-4"
              outline={generateContractPending}
              onClick={handleGenerateContract}
              disabled={generateContractPending}
            >
              Reprocessar contrato
            </Button>
          </div>
        </Col>
      </Row>
    </CardBody>
  </Card>
);
