import { InstanceError } from '@apptypes/instance';
import { IPagination } from '@apptypes/pagination';
import { AUTH_COOKIE_NAME } from '@helpers/constants';
import { getCookie } from '@helpers/cookies';
import { generateToQueryString } from '@helpers/queryString';
import { ERROR_STATUS_MESSAGE } from '@helpers/request/constants';
import instance from '@helpers/request/instance';
import { IClientCreate } from '@modules/Client/types';
import { ColumnFiltersState } from '@tanstack/react-table';

export const getAll = async (
  page = 1,
  size = 10,
  filterItems?: ColumnFiltersState,
): Promise<IPagination<IClientCreate>> => {
  try {
    const queryString = generateToQueryString({ page, size, filterItems });
    const { data } = await instance.get(`/clients${queryString}`);
    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Clientes não encontrados',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const getByID = async (clientID: string): Promise<IClientCreate> => {
  try {
    const { data } = await instance.get(`/clients/${clientID}`);
    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Cliente não encontrado',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const create = async (payload: IClientCreate): Promise<IClientCreate> => {
  try {
    if (payload.type === 'J') {
      payload.gender = undefined;
    }

    const { data } = await instance.post('/clients', payload);
    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível criar o cliente',
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Cliente não encontrado',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const update = async (clientID: string, payload: IClientCreate): Promise<IClientCreate> => {
  try {
    const { data } = await instance.put(`/clients/${clientID}`, payload);
    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível editar o cliente',
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'Cliente não encontrado',
      [ERROR_STATUS_MESSAGE.UNAUTHORIZED]: 'Você não tem permissão para editar o cliente',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const deleteByID = async (clientID: string): Promise<void> => {
  try {
    await instance.delete(`/clients/${clientID}`);
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível deletar o cliente',
      [ERROR_STATUS_MESSAGE.UNAUTHORIZED]: 'Você não tem permissão para deletar o cliente',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const exportData = async (page = 1, size = 1000, filterItems?: ColumnFiltersState) => {
  try {
    const queryString = generateToQueryString({ page, size, filterItems });
    const file = await fetch(`${process.env.BASE_URL}/clients/report/export${queryString}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: getCookie(AUTH_COOKIE_NAME),
      } as any,
    });

    const response = await file.arrayBuffer();
    const fileBlog = new Blob([response], { type: 'text/csv' });

    return URL.createObjectURL(fileBlog);
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível buscar o arquivo',
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'arquivo não encontrado',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};
